import React, { useState, useContext, useEffect } from 'react';
import Select, { components } from 'react-select';
import axios from 'axios';

import './styles.scss';
import { states } from './mockedData';
import HubbleOrderContext from '../../../context/hubbleOrderContext';
import StepWrapper from '../StepWrapper';

const Doctor = ({ steps, setActiveSteps, isV3 }) => {
  const { doctor, setDoctor } = useContext(HubbleOrderContext);
  const [selectedState, setSelectedState] = useState('');
  const [doctorsList, setDoctorsList] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState('');
  const [isError, setIsError] = useState(false);
  const [isFindDoctor, setIsFindDoctor] = useState(false);
  const [isFindDoctorError, setIsFindDoctorError] = useState(false);
  const [doctorValues, setDoctorValues] = useState({
    name: '',
    clinic_name: '',
    phone: '',
    city: '',
    state: ''
  })

  const handleInputChange = (e) => {
    const { name, value } =  e.target;
    setDoctorValues(prevState => ({...prevState, [name]: value}))
  }

  const handleSubmit = () => {
    setIsError(false);
    setIsFindDoctorError(false);

    const currentRef = steps.find(({ title }) => title === 'Checkout')

    if (isFindDoctor) {
      if (Object.values(doctorValues).some(value => !value)) {
        return setIsFindDoctorError(true)
      }

      return createDoctor(doctorValues)
        .then(response => {
          setDoctor({
            id: response.data.data.doc.id,
            ...doctorValues
          })
          setActiveSteps({ Checkout: true });
          setTimeout(() => {

            currentRef.ref.current.scrollIntoView({behavior: 'smooth'})
          }, 200);
        })
    }

    if (!selectedState || !selectedDoctor) return setIsError(true);


    setDoctor(selectedDoctor);
    setActiveSteps({ Checkout: true });
    setTimeout(() => {
      currentRef.ref.current.scrollIntoView({behavior: 'smooth'})
    }, 200);
  };

  const getDoctors = (state) =>
    axios.get(
      `https://api.hubblecontacts.com/v1/docs/search.json?state=${state}`
    );

  const createDoctor = (doctor) =>
    axios.post(`${process.env.GATSBY_ADMIN_DOC_URL}/v1/docs.json`, doctor, { headers: {
      'Access-Control-Allow-Origin' : '*',
      'Access-Control-Allow-Credentials':true,
      'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }})

  const handleSelectState = (e) => {
    setSelectedState(e);
    setDoctorsList([]);
    setSelectedDoctor('');
    setDoctorValues(prevState => ({...prevState, state: e?.value}))

    getDoctors(e.value).then((response) => {
      const newDoctorsList = response.data.data.docs.map((doc) => ({
        ...doc,
        value: doc.id,
      }));
      setDoctorsList(newDoctorsList);
    });
  };

  const handleSelectDoctor = (e) => {
    setSelectedDoctor(e);
    setDoctor(e)
    if (typeof window !== "undefined") {
      window.dataLayer.push({
        event: "find_my_doctor",
        doctor_state: e?.state,
        doctor_name: e?.name
      })
    }
  };

  const customFilter = (option, inputValue) => {
    const { name, clinic_name, postcode } = option.data;

    return (
      !inputValue ||
      `${name} ${clinic_name} ${postcode}`.toLowerCase().includes(inputValue)
    );
  };

  useEffect(() => {
    if (selectedState && selectedDoctor) {
       handleSubmit()
       setIsError(false);
     }
   }, [selectedState, selectedDoctor])

  return (
    <StepWrapper
      title='Find your eye doctor'
      steps={steps}
      currentStep='Doctor'
      onSubmit={handleSubmit}
      className='doctor'
      isV3={isV3}
    >
      <div className='state-container'>
        <label>My Doctor’s home state:</label>
        <Select
          className={`hubble-select doctor-state ${
            isError && !selectedState ? 'error' : ''
          }`.trim()}
          classNamePrefix='HubbleSelect'
          value={selectedState}
          onChange={handleSelectState}
          placeholder='--'
          options={states}
        />
      </div>
      <Select
        className={`hubble-select doctor ${
          isError && !selectedDoctor ? 'error' : ''
        }`.trim()}
        classNamePrefix='HubbleSelect'
        value={(!isFindDoctor && selectedDoctor) || null}
        onChange={handleSelectDoctor}
        placeholder='Search by doctor, clinic name, or zip code'
        options={doctorsList}
        isDisabled={!doctorsList.length || isFindDoctor}
        components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
        filterOption={customFilter}
      />
      <a className='doctor-issue' onClick={() => setIsFindDoctor(true)}>Can’t find your doctor?</a>
      {isFindDoctor &&
        <div className='find-doctor-container'>
          <div className='inputs-container'>
            <input
              type="text"
              name="name"
              className={`form-input ${
                isFindDoctorError && !doctorValues.name
                  ? 'error'
                  : ''
              }`.trim()}
              onChange={(e) => handleInputChange(e)}
              value={doctorValues.name}
              placeholder="Doctor's Name"
              maxLength={64}
            />
            <input
              type="text"
              name="clinic_name"
              className={`form-input ${
                isFindDoctorError && !doctorValues.clinic_name
                  ? 'error'
                  : ''
              }`.trim()}
              onChange={(e) => handleInputChange(e)}
              value={doctorValues.clinic_name}
              placeholder="Clinic Name"
              maxLength={64}
            />
            <input
              type="text"
              name="phone"
              className={`form-input ${
                isFindDoctorError && !doctorValues.phone
                  ? 'error'
                  : ''
              }`.trim()}
              onChange={(e) => handleInputChange(e)}
              value={doctorValues.phone}
              placeholder="Phone"
              maxLength={18}
            />
            <input
              type="text"
              name="city"
              className={`form-input ${
                isFindDoctorError && !doctorValues.city
                  ? 'error'
                  : ''
              }`.trim()}
              onChange={(e) => handleInputChange(e)}
              value={doctorValues.city}
              placeholder="City"
              maxLength={64}
            />
          </div>
          <a className='doctor-issue' onClick={() => setIsFindDoctor(false)}>Cancel</a>
        </div>
      }
    </StepWrapper>
  );
};

const CustomOption = (props) => {
  const { clinic_name, name, postcode } = props.data;

  return (
    <components.Option {...props}>
      <div className='name'>Name: {name}</div>
      <div className='clinic-name'>Clinic Name: {clinic_name}</div>
      <div className='zip'>ZIP: {postcode}</div>
    </components.Option>
  );
};

const CustomSingleValue = (props) => {
  const { name, postcode } = props.data;

  return (
    <components.SingleValue {...props}>
      <div className='name'>{name}</div>
      <div className='zip'>{postcode}</div>
    </components.SingleValue>
  );
};

export default Doctor;

import React, { useState, useContext, useEffect, useCallback} from "react";
import { navigate } from "gatsby";
import StepWrapper from '../StepWrapper';
import HubbleOrderContext from "../../../context/hubbleOrderContext";
import ReactDOM from "react-dom";
import axios from "axios";
import { CardElement,PaymentRequestButtonElement, useStripe, useElements} from '@stripe/react-stripe-js';
import PrescriptionRadio from "../../PrescriptionRadio"
import paypal from 'paypal-checkout';
import braintree from 'braintree-web';
// import ReactPixel from 'react-facebook-pixel';
import ReactGA from "react-ga";
import tiktokTrack from "../TiktokTrack";
import "./styles.scss"
import WebpImage from "../../WebpImage";
import { lensesData } from "../../CheckoutCustomerInfo/mockedData";
import { usePromo, promoStart, promoEnd } from "../../../utils/usePromo";
import { zipRegEx } from "../../../utils/variables";
import { isValidEmail } from '../../../services/utils';
import Select from "react-select";
import {states} from "../Doctor/mockedData";

const Checkout = ({ steps, isHydroSkyhy, isAffiliatePage ,isIronclad}) => {
  const {hubbleOrderContext, prescription, doctor, taxes, brand, setTaxes, shippingAddress, setSubmitButtonText, setShippingAddress, clearShippingAddress} = useContext(HubbleOrderContext);
  ReactGA.initialize('UA-74901424-1');
  let search = typeof window !== "undefined" ? window?.location?.search : null
  let params = new URLSearchParams(search);
  let utm_source = params.get('utm_source');
  let utm_medium = params.get('utm_medium');
  let utm_content = params.get('utm_content');
  let utm_campaign_param = params.get('utm_campaign');
  let gclid = params.get("gclid");
  const [submit_copy, setSubmitCopy] = useState("Complete Order")
  const [cardErrors, setCardErrors]= useState("")
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paypallPaymentId, setPaypallPaymentId] = useState(null)
  const [ironclad, setIronclad] = useState("")
  const [ironcladWarning, setIronCladWarining] = useState(false)
  const [isMobilePay, setMobilePay] = useState(false)
  const [currency] = useState('USD')
  const [stripeCountry] = useState('US')
  const [shipping, setShipping] = useState('$0.00')
  const [subTotal, setSubTotal] = useState("0")
  const [total, setTotal] = useState("0")
  const [totalWithShipmentAndTaxes, setTotalWithShipmentAndTaxes] = useState("0")
  const [quantity, setQuantity] = useState(0)
  const [subscriptionDisclaimer, setSubscriptionDisclaimer] = useState('')
  const [isCcProduct, setIsCcProduct] = useState( brand.cc_product_id !== "hubble" && brand.cc_product_id !== "hydro" && brand.cc_product_id !== "skyhy" && brand.cc_product_id !== "classic")
  const stripe = useStripe();
  const elements = useElements();
  let pr = null
  const isPromo = usePromo(promoStart, promoEnd)
  const [values, setUser] = useState({
    email: "",
    sms_subscribe: "",
    first_name: "",
    last_name:"",
    address1: "",
    address2: "",
    city:"",
    province: "",
    zipcode: '',
    payment_method: null,
    paymentType: "",
    phoneNumber: '',
    right_eye_power: prescription.rightEye.power.value,
    left_eye_power: prescription.leftEye.power.value,
    doctor_id: doctor.id,
    line_items: [],
    cadence: "28 days",
    country: "US",
    order_number: "",
    variant_id: "",
    note: gclid,
    utm_campaign: utm_campaign_param,
    utm_content: utm_content,
    utm_source: utm_source,
    utm_medium: utm_medium,
    website_source: "base",
    hubbleProductID: "",
    prescriptions: [],
    price: 1,
    name: "Hubble Customer",
    url: "intake.hubblecontacts.com",
    taxes: taxes,
  })
  const [isEmailValidationError, setIsEmailValidationError] = useState(false)
  const [isFirstTimeEmailEdit, setIsFirstTimeEmailEdit] = useState(true)

  const getLineItem = () => {
    const contactsType = () => {
      if (brand.title.toLowerCase().includes("hydro")) return "hydro"
      if (brand.title.toLowerCase().includes("skyhy")) return "skyhy"
      if (brand.value !== "hubble" && brand.value !== "hydro" && brand.value !== "skyhy") return "cc"

      return "classic"
    }
    const isCcProduct = contactsType() === "cc"
    const subPrice = contactsType() === "classic" ? "1.00" : brand.sale_price

    const item = {
      item_name: isCcProduct
        ? brand.title
        : lensesData[contactsType()]?.subName,
      currency: "USD",
      discount: 0,
      item_brand: isCcProduct ? brand.value : "Hubble",
      cadence: 28,
      item_category: "contacts",
      quantity: isCcProduct ? 1 : 2,
      price: +subPrice * 2,
      prescription: prescription,
      line_item_type: "contacts",
      contacts_type: contactsType(),
      other_products: "",
      lens_quantity: isCcProduct ? null : 30,
      product_id: isCcProduct
        ? brand.cc_product_id
        : lensesData[contactsType()]?.subProductId,
      variant_id: null,
    }

    return item
  }

  const [errors, setErrors] = useState({
    email: false,
    first_name: false,
    last_name:false,
    address1: false,
    city:false,
    province: false,
    zipcode: false,
    phone: false,
    paymentType: false,
  })

  // braintree things
  let PayPalButton = paypal.Button.driver('react', { React, ReactDOM });
  const [addresses, setAddresses] = useState([])

  // paypal payments
  const payment = (data, actions) => {
    return actions.braintree.create({
      flow: 'vault',
      amount: '1.00',
      currency: currency
    });
  }

  useEffect(() => {
    if (shippingAddress.zipcode && shippingAddress.zipcode.length === 5 && zipRegEx.test(shippingAddress.zipcode)) {
      const isHydroOrSkyhy = brand?.value === 'hydro' || brand?.value === 'skyhy'
      const tax_amount = parseFloat(total + (isHydroOrSkyhy ? 3.99 : 0)).toFixed(2)
      const address = {
        ...shippingAddress,
        address_opt: shippingAddress.address2 || null,
        state: shippingAddress.province,
        zip: shippingAddress.zipcode
      }
      const tax_params = { ...address, subTotal: tax_amount }

      axios.post(`${process.env.GATSBY_API_URL}/api/v1/avalara/get_tax_rate`, { tax_params }, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods":
          "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
        }).then(response => {
          const tax_rate_amounts = response.data?.tax_rate_amounts
          if (+tax_params.subTotal === 1) {
            return setTaxes(0)
          }

          setTaxes(tax_rate_amounts?.toFixed(2))
        })

      if (typeof window !== "undefined") {
        window.dataLayer.push({ ecommerce: null })
        window.dataLayer.push({
          event: "shipping_details",
          shipping_state: addresses.province,
          shipping_city: addresses.city,
        })
      }
    }
  }, [shippingAddress.zipcode, shippingAddress.province])

  const getSubscriptionDisclaimer = useCallback((isHubble, price, sale_price, percent_off, days) => {
    const isHydroOrSkyhy = brand?.value === 'hydro' || brand?.value === 'skyhy'
    let hubbleSubscription = `By completing your purchase of $1.00 for two weeks of Hubble contacts, you agree to sign up for a Hubble Subscription and to Hubble’s <a href="https://tos.hubblecontacts.com/" target="_blank">Terms of Service</a> and <a href="https://www.hubblecontacts.com/privacy-policy/" target="_blank">Privacy Policy</a>. Hubble will bill you $39.98 + $3.99 S+H plus tax every 28 days until you cancel or change the frequency of your shipments, which you can do at any time through our <a href="https://www.hubblecontacts.com/login" target="_blank">customer portal<a/> or by <a href="https://www.hubblecontacts.com/contact-us" target="_blank">contacting us</a>. Your subscription will begin at least 14 days after you complete your order (but may be slightly delayed if we need more time to contact your eye care provider).`
    let permissionToContact = "You are also giving us permission to contact your eye care provider regarding your order, which we are required to do if you do not provide us with a copy of your prescription. If we need to contact your eye care provider again in the future, we’ll tell you first."

    if (isIronclad){
      hubbleSubscription = `Beginning at least 14 days after you complete your order, Hubble will charge you $39.98 + $3.99 S+H (plus tax where applicable) every 28 days until you modify or cancel your subscription which you can do at any time through the <a href="https://www.hubblecontacts.com/login" target="_blank">customer portal<a/> or by <a href="https://www.hubblecontacts.com/contact-us" target="_blank">contacting us</a>.`
      permissionToContact = ""
    }
    if (isHubble) return `${hubbleSubscription} ${permissionToContact}`


    let hydroOrSkyhySubscription = `By completing this purchase you agree to sign up for a Hubble subscription and to Hubble’s <a href='https://tos.hubblecontacts.com/' target="_blank">Terms of Service</a> and <a href='https://www.hubblecontacts.com/privacy-policy' target="_blank">Privacy Policy<a/>. After your first discounted order, we will bill you $${price} + $3.99 S+H and tax every 28 days until you modify or cancel your subscription, which you can do at any time through our <a href='https://www.hubblecontacts.com/login' target="_blank">customer portal</a> or by <a href="https://www.hubblecontacts.com/contact-us" target="_blank">contacting us</a>. You will receive an order confirmation email with the details of your order and a link to track its progress. You are also giving us permission to contact your eye care provider regarding your order, which we are required to do if you do not provide us with a copy of your prescription. If we need to contact your eye care provider again in the future, we’ll tell you first.`
    if (isIronclad){
      hydroOrSkyhySubscription = `After you complete your order, Hubble will charge your payment method for the discounted total and then $${price} + $3.99 S+H (plus tax where applicable) every 28 days until you modify or cancel your subscription, which you can do at any time through the <a href='https://www.hubblecontacts.com/login' target="_blank">customer portal</a> or by <a href="https://www.hubblecontacts.com/contact-us" target="_blank">contacting us</a>.`
    }
    if (isHydroOrSkyhy) return hydroOrSkyhySubscription

    let contactsCartSubscription = 'By completing your purchase, you agree to sign up for a ContactsCart subscription.'

    if (percent_off) {
      contactsCartSubscription += ` We will bill you $${(sale_price).toFixed(2)} (after ${percent_off}% off) for your first order of contacts, then after ${days} days you will be billed $${price} for ${days} days of contacts, followed by $${price} every ${days} days (plus tax if charged in your state) until you cancel or update your subscription.`
    } else {
      contactsCartSubscription += ` We will bill you $${price} for ${days} days of subscription, followed by $${price} every ${days} days (plus tax if charged in your state) until you cancel or update your subscription.`
    }
    if (isIronclad){
      if (percent_off) {
        contactsCartSubscription = `After you complete your order, Hubble will charge your payment method for the discounted total and then $${price} (plus tax where applicable) every ${days} days until you modify or cancel your subscription, which you can do at any time, including by <a href="htttp://www.contactscart.com" target="_blank">calling or chatting with us.</a>`
      } else {
        contactsCartSubscription = `After you complete your order, Hubble will charge your payment method for the discounted total and then $${price} (plus tax where applicable) every ${days} days until you modify or cancel your subscription, which you can do at any time, including by <a href="htttp://www.contactscart.com" target="_blank">calling or chatting with us.</a>`
      }
    }
    return `${contactsCartSubscription} ${permissionToContact}`
  }, [brand])

  useEffect(() => {
    const {value} = brand
    const isHubble = value === 'hubble'
    const isHydroOrSkyhy = value === 'hydro' || value === 'skyhy'
    if (!stripe || (!isHubble && !taxes)) {
      // We can't create a PaymentRequest until Stripe.js loads.
      return;
    }
    let parsedTotal = parseFloat(subTotal)
    let stripeTotal =  Math.round(parsedTotal * 100)

    if (isHydroOrSkyhy) {
      stripeTotal += 399
    }

    if (!isHubble) {
      stripeTotal += (taxes * 100)
    }

    pr = stripe.paymentRequest({
      country: stripeCountry,
      currency: currency.toLowerCase(),
      total: {
        label: 'Hubble Contacts Trial',
        amount: stripeTotal,
      },
    });

    pr.on('paymentmethod', async (ev) => {
      setPaymentMethod(ev.paymentMethod.id)
      setMobilePay(true)
      ev.complete('success');
    })
    pr.canMakePayment().then((canMakePaymentRes) => {
      if (canMakePaymentRes) {
        setPaymentRequest(pr);
      }
    }).catch((e)=>{
      console.log(e)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe, taxes]);

  const updatePaymentRequestInstance = async (event) => {
    const isHydroOrSkyhy = value === "hydro" || value === "skyhy"
    const isHubble = value === 'hubble'
    let parsedTotal = parseFloat(subTotal)
    let stripeTotal =  Math.round(parsedTotal * 100)

    if (isHydroOrSkyhy) {
      stripeTotal += 399
    }

    if (!isHubble) {
      stripeTotal += (taxes * 100)
    }

    console.log(stripeTotal)
    await pr.update({
      total: {
        label: "Hubble Contacts Trial",
        amount: stripeTotal,
      },
    })
  }

  useEffect(() => {
    if (!brand || !prescription) return

    const { price, sale_price, value, days, percent_off } = brand
    const tempIsHubble = value === 'hubble'
    const isHydroOrSkyhy = value === 'hydro' || value === 'skyhy'
    const tempPrice = Number(sale_price) || Number(price)
    const tempQuantity = Object.keys(prescription).length
    const shippingPrice = isHydroOrSkyhy ? 3.99 : 0
    const tempTotal = tempPrice * tempQuantity + +taxes
    const total = tempPrice * tempQuantity + shippingPrice + +taxes
    const tempSubscriptionDisclaimer = getSubscriptionDisclaimer(
      tempIsHubble,
      Number(price) * tempQuantity,
      Number(sale_price) * tempQuantity + +taxes,
      percent_off,
      days,
    )

    setSubTotal(tempIsHubble ? tempPrice.toFixed(2) : (tempPrice * tempQuantity).toFixed(2))
    setTotal(tempIsHubble ? tempPrice.toFixed(2) : tempTotal.toFixed(2))
    setTotalWithShipmentAndTaxes(tempIsHubble ? tempPrice.toFixed(2) : total.toFixed(2))
    setQuantity(tempIsHubble ? 1 : tempQuantity)
    setSubscriptionDisclaimer(tempSubscriptionDisclaimer)
    setIsCcProduct(brand.cc_product_id !== "hubble" && brand.cc_product_id !== "hydro" && brand.cc_product_id !== "skyhy" && brand.cc_product_id !== "classic")
    setUser({
      ...values,
      left_eye_power: prescription.leftEye.power,
      right_eye_power: prescription.rightEye.power,
      line_items: [getLineItem()],
    })
  }, [hubbleOrderContext, taxes, prescription, shippingAddress, brand])


  useEffect(() => {
    setUser({
      ...values,
      left_eye_power: prescription.leftEye.power,
      right_eye_power: prescription.rightEye.power,
      line_items: [getLineItem()],
    })
  }, [prescription])

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.dataLayer.push({ecommerce: null})
      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          items: [
            {
              item_id: brand.cc_product_id,
              item_name: brand.title,
              currency: "USD",
              discount: 0,
              item_brand: brand.title.includes("Hubble") ? "Hubble" : brand.title,
              item_category: "contacts",
              item_category2: 'spherical',
              item_category3: 'N/A', //gender
              item_category4: 'N/A',
              item_category5: 'N/A',
              item_list_id: 'N/A',
              item_variant: 'N/A',
              index: 1,
              price: parseFloat(brand.sale_price)
            }
          ]
        }
      })

      window.dataLayer.push({ecommerce: null})
      window.dataLayer.push({
        event: "begin_checkout",
        ecommerce: {
          items: [
            {
              item_id: brand.cc_product_id,
              item_name: brand.title,
              currency: "USD",
              discount: "",
              item_brand: brand.title.includes("Hubble") ? "Hubble" : brand.title,
              item_category: "contacts",
              item_category2: '/N/A',
              item_category3: 'N/A', //gender
              item_category4: 'N/A',
              item_category5: 'N/A',
              item_list_id: 'N/A',
              item_variant: 'N/A',
              quantity: 1,
              price: parseFloat(brand.sale_price)
            }
          ]
        }
      })
    }
    setUser((prevState) => ({
      ...prevState,
      paymentType: "stripe"
    }))
  },[])

  const onAuthorize = (payload, actions) =>  {
    setPaypallPaymentId(payload.nonce)
    setUser({...values, payment_method:  payload.nonce, paymentType: 'braintree'})
    setErrors((prevState) => ({
      ...prevState,
      paymentType: false
    }))
  }

  const handleInputChange = (e) => {
    const {name, value} =  e.target;
    if (name === 'email') {
      setErrors({...errors, [name]: false})
      const isEmailValid = isValidEmail(values.email)
      if (isEmailValid || !value) {
        setIsEmailValidationError(false)
      }
      if (!isEmailValid && !isFirstTimeEmailEdit) {
        setIsEmailValidationError(true)
      }
      if (value === '' && !isFirstTimeEmailEdit) {
        setErrors({...errors, [name]: true})
      }
    }
    setUser({ ...values, [name]: value })
    setShippingAddress({ ...shippingAddress, [name]: value })
  }

  const handleRadioChange = (paymentType) => {
    if (paymentType === "stripe") {
      setPaypallPaymentId(null)
    }

    setUser((prevState) => ({
      ...prevState,
      paymentType
    }))
    setErrors((prevState) => ({
      ...prevState,
      paymentType: false
    }))
    tiktokTrack('Registration')
  }

  const loqate = () => {
    // TODO: hook up int'l addresses

    var url = 'https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws';
    var params = '';
        params += "&Key=" + encodeURIComponent("HG95-GT79-MD54-PP92");
        params += "&Text=" + encodeURIComponent(values.address1);
        params += "&Countries=" + encodeURIComponent("USA");
        params += "&IsMiddleware=" + encodeURIComponent(false);
        params += "&Limit=" + encodeURIComponent(10);
        params += "&Language=" + encodeURIComponent("en-gb");
    var http = new XMLHttpRequest();
    http.open('POST', url, true);
    http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    http.onreadystatechange = function() {
      if(http.readyState == 4 && http.status == 200) {
          var response = JSON.parse(http.responseText);
          // Test for an error
          if (response.Items.length == 1 && typeof(response.Items[0].Error) != "undefined") {
            // Show the error message
            // alert(response.Items[0].Description);
          }
          else {
            // Check if there were any items found
            if (response.Items.length >= 0)
              setAddresses(response.Items)
          }
        }
      }
      http.send(params);
  }

  const klaviyoTrack = (e)  =>{
    const {name, value} =  e.target;

    if (typeof window !== "undefined") {
      (window)._learnq.push(['identify', {
        '$email' : value,
      }]);

      (window)._learnq.push(['track', 'Started Checkout', {
        "Items": [{
          "SKU": "",
          "Name": "Hubble Daily Contact Lenses",
          "Quantity": 1,
          "RowTotal": 0,
          "ProductURL": "https://hubble-prod.myshopify.com/products/hubble-daily-contact-lenses",
          "ImageURL": "https://cdn.shopify.com/s/files/1/1516/5228/products/Monthly_593863be-246f-49ea-8c69-273237b1c82d_large.jpg",
        }
      ]
      }]);
    }
  }
  // stripe payments

  const convertBase64toFriendlyBase64 = (str) => {
    const safe = str.replace(/\\+/g, '-').replace(/\//g, '_').replace(/\\=+$/, '');
    return encodeURIComponent(safe)
  }

  const encodeJSON = (json) => {
    const jsonAsString = JSON.stringify(json);
    return btoa(convertBase64toFriendlyBase64(jsonAsString));
  }

  const stripeSubmitCheckout = async (values, userToken) => {
    values.taxes = taxes
    const utm_params = search.replace("?", "")
    const headers = {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }

    await axios.post(`${process.env.GATSBY_API_URL}/api/v1/stripe/checkout`, values, headers
    ).then((response) => {

    if (response.data.client_secret === "There was an error with your payment" || response.data.client_secret === undefined) {
      setCardErrors("There was an error with your payment" )
      setSubmitButtonText("Complete Order")
      setSubmitCopy("Complete Order")
      return
    }
    if (typeof window !== "undefined") {
      if (window.gtag !== null) {
        window.dataLayer.push({ ecommerce: null })
        window.dataLayer.push({
          event: "purchase",
          ecommerce: {
            transaction_id: `${btoa(values?.email)}-${Date.now()}`,
            value: parseFloat(total),
            tax: 0,
            shipping: 0,
            currency: "USD",
            coupon: "N/A",
            paymentType: "stripe",
            items: [
              {
                item_id: brand.cc_product_id,
                item_name: brand.title,
                currency: "USD",
                discount: 0,
                item_brand: brand.title.includes("Hubble") ? "Hubble" : brand.title,
                item_category: "contacts",
                item_category2: 'spherical',
                item_category3: 'N/A', //gender
                item_category4: 'N/A',
                item_category5: 'N/A',
                item_list_id: 'N/A',
                item_variant: 'N/A',
                index: 1,
                price: parseFloat(brand.sale_price)
              }
            ]
          }
        });
      }
    }
    // setSubmitButtonText("Complete Order")
    // setSubmitCopy("Complete Order")

      const order_values = {
        email: values.email,
        order_number: response.data.order_number,
        name: `${values.first_name} ${values.last_name}`,
        address1: values.address1,
        city: values.city,
        province: values.province,
        zip: values.zipcode,
        country: stripeCountry,
        url: typeof window !== "undefined" ? window?.location?.href : null,
        price: 1,
        variantId: 19809451933769,
        contacts_cart_order: brand.cc_product_id !== "",
      }
      const encoded_values = encodeJSON(order_values)

      navigate(`/pages/thanks/?data=${encoded_values}&${utm_params}&order_value=1&order_currency=${currency}`)
      setTimeout(() => {
        setTaxes(0)
        clearShippingAddress()
      }, 2000)
    }).catch((error) => {
      if (error.response.data.errors?.length && error.response.data?.errors[0] === "discount already used by customer") {
        setCardErrors("This discount code has already been used")
      } else if (error.response.data.errors?.length && error.response?.data?.errors[0]) {
        setCardErrors(error.response.data.errors[0].slice(0, 1).toUpperCase() + error.response.data.errors[0].slice(1))
      } else {
        setCardErrors("There is an error with your payment")
      }

      setSubmitButtonText("Complete Order")
      setSubmitCopy("Complete Order")
    })
  }

  const stripeUserCreate =  async ( values ) => {
  const headers = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }
  }
  await axios.post(`${process.env.GATSBY_API_URL}/api/v1/stripe/sign_up`, values, { headers })
  .then((response) => {
    const userToken = response.data.token

    const headers = {
      headers: {
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        Authorization: `Bearer ${userToken}`
      }
    }

    axios.get(`${process.env.GATSBY_API_URL}/api/v1/stripe/customers`, headers)
    .then((response) => {
      if (typeof window !== "undefined") {
        if (window.gtag !== null) {
          window.dataLayer.push({'user_id': response.data.id})
        }
      }
      values = { ...values, stripe_customer_id: response.data.id}
      stripeSubmitCheckout(values, userToken)
    })
    }).catch(() => {
      setCardErrors("There is an error with your payment")
      setSubmitButtonText("Complete Order")
      setSubmitCopy("Complete Order")
    })
  }

  const submitUser = (values) => {
    const utm_params = search.replace('?', '')

    if (isPromo && values.line_items.some(({contacts_type: t}) => t === "skyhy" || t === "hydro"))
      values.isMemorial2024 = true

    if (values.paymentType === "stripe" && !isCcProduct) {
      stripeUserCreate(values)
      return
    }

    if (!!paypallPaymentId) {
      values = { ...values, payment_method: paypallPaymentId, paymentType: "braintree"}
    }

    let checkout_url = `${process.env.GATSBY_API_URL}/stripe/create-combo-charge`
    if (isCcProduct) {
      checkout_url = `${process.env.GATSBY_API_URL}/stripe/cc-create-charge`
    }

    values.taxes = taxes
    axios.post(checkout_url,values, { headers: {
      'Access-Control-Allow-Origin' : '*',
      'Access-Control-Allow-Credentials':true,
      'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }}).then((response) => {
      if ( response.data.order_number == "There was an error with your payment" || response.data.order_number === undefined) {
        setCardErrors("There was an error with your payment" )
        setSubmitButtonText("Complete Order")
        setSubmitCopy("Complete Order")
        return
      }
      const order_values =   {
        email: values.email ,
        order_number: response.data.order_number ,
        name: `${values.first_name} ${values.last_name}`,
        address1: values.address1 ,
        city: values.city,
        province: values.province ,
        zip: values.zipcode,
        country: stripeCountry,
        url: typeof window !== "undefined" ? window?.location?.href : null,
        price: 1 ,
        variantId: 19809451933769,
        contacts_cart_order: brand.cc_product_id !== ""
      }
      if (typeof window !== "undefined") {
        window.dataLayer.push({ecommerce: null})
        window.dataLayer.push({
          event: "add_payment_info",
          ecommerce: {
          paymentType: "stripe",
          items: [
            {
              item_id: brand.cc_product_id,
              item_name: brand.title,
              currency: "USD",
              discount: 0,
              item_brand: brand.title.includes("Hubble") ? "Hubble" : brand.title,
              item_category: "contacts",
              item_category2: 'spherical',
              item_category3: 'N/A', //gender
              item_category4: 'N/A',
              item_category5: 'N/A',
              item_list_id: 'N/A',
              item_variant: 'N/A',
              index: 1,
              price: parseFloat(brand.sale_price)
            }
          ]
        }
      })

      window.dataLayer.push({
        event: "add_shipping_info",
        ecommerce: {
          paymentType: "stripe",
          items: [
            {
              item_id: brand.cc_product_id,
              item_name: brand.title,
              currency: "USD",
              discount: 0,
              item_brand: brand.title.includes("Hubble") ? "Hubble" : brand.title,
              item_category: "contacts",
              item_category2: 'spherical',
              item_category3: 'N/A', //gender
              item_category4: 'N/A',
              item_category5: 'N/A',
              item_list_id: 'N/A',
              item_variant: 'N/A',
              index: 1,
              price: parseFloat(brand.sale_price)
            }
          ]
        }
      })

      window.dataLayer.push({
        event: "purchase",
        ecommerce: {
          coupon: "",
          currency: 'USD',
          shipping: 0,
          tax: 0,
          transaction_id: `${btoa(values?.email)}-${Date.now()}`,
          website_source: "intake-hubble-lp",
          value: parseFloat(total),
          paymentType: "stripe",
          items: [
            {
              item_id: brand.cc_product_id,
              item_name: brand.title,
              currency: "USD",
              discount: 0,
              item_brand: brand.title.includes("Hubble") ? "Hubble" : brand.title,
              item_category: "contacts",
              item_category2: 'spherical',
              item_category3: 'N/A', //gender
              item_category4: 'N/A',
              item_category5: 'N/A',
              item_list_id: 'N/A',
              index: 1,
              item_variant: 'N/A',
              quantity: 1,
              price: parseFloat(brand.sale_price)
            }
          ]
        }
      })
    }

      const encoded_values=encodeJSON(order_values)
      // setSubmitButtonText("Complete Order")
      // setSubmitCopy("Complete Order")

      navigate(`/pages/thanks/?data=${encoded_values}&${utm_params}&order_value=1&order_currency=${currency}`)
      setTimeout(() => {
        setTaxes(0)
        clearShippingAddress()
      }, 2000)
    }).catch(()=>{
      setCardErrors("There was a problem with your payment")
      setSubmitButtonText('Complete Order')
      setSubmitCopy("Complete Order")
    })
  }

  const autoFillAddress = (address) => {
    let splitCityZip = address.Description.match(/[\d\.]+|\D+/g);
    let addressCity = splitCityZip[0].split(" ")
    addressCity.pop()
    let state = addressCity[addressCity.length - 1]
    addressCity.pop()
    let city =  addressCity.join(" ")
    setAddresses([])
    setUser({ ...values, address1: address.Text, zipcode: splitCityZip[1], city: city, province: state })
    setShippingAddress({
      first_name : values.first_name,
      last_name: values.last_name,
      address: address.Text,
      city: city,
      province: state,
      zipcode: splitCityZip[1],
    })
  }


  const handleClickWrap = () => {
    setIronclad(ironclad === "" ? "ironclad" : "")
    setIronCladWarining(false)
    if (window !== undefined) {
      window._ps("set", "signer_id", values?.email);
      window._ps("send", "updated", { custom_data: { first_name: values?.first_name , values: values?.last_name } });
      window._ps("mainwebsite-tos:send", "agreed", {
        // The event_callback function will be invoked once the "send" is complete.
        event_callback: function(err, eventType, clickwrapGroup, payload) {
          if (err) {
            // The send encountered an error.
          }
          // setCheckoutButtonDisabled(false)
          // The send is complete and acceptance was captured successfully.
        }
      });
    }
  }


  // for mobile pay
  useEffect(() => {
    if (paymentMethod !== null && isMobilePay) {
      setUser({...values, payment_method: paymentMethod, paymentType: "stripe"})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobilePay]);

  useEffect(() => {
    if (values.paymentType === "stripe" && isMobilePay === false) {
      submitUser(values)
    }
    if (values.paymentType === "stripe" && isMobilePay){
      handleSubmit(new Event('build'))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.payment_method]);

  const handleSubmit = async (event) => {
    if(isIronclad && ironclad === ""){
      setIronCladWarining(true)
      return
    }
    if ( values.address1.includes("1721 Flowers")){
      return false
    }

    const isZipInvalid = shippingAddress?.zipcode && !zipRegEx.test(shippingAddress.zipcode)
    // Block native form submission.
    if (Object.values(errors).some(error => error) || isEmailValidationError || isZipInvalid) {
      return false;
    }

    const tempErrors = Object.keys(errors).reduce((result, key) => {
      if (!values[key]) return { ...result, [key]: true }

      return result
    }, {})

    if (Object.keys(tempErrors).length) {
      return setErrors(errors => ({
        ...errors,
        ...tempErrors
      }))
    }

    event.preventDefault();
    setSubmitButtonText('SUBMITTING...')
    setSubmitCopy("SUBMITTING...");

    if (values.paymentType === "stripe" && isMobilePay === false ) {
      if (!stripe || !elements) {
        return;
      }
      const cardElement = elements.getElement(CardElement);
      const responseToken = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      })
      if ( responseToken?.paymentMethod?.card.funding == "prepaid") {
        setSubmitButtonText("Complete Order")
        setSubmitCopy("Complete Order")
        setCardErrors("Oops! We’re sorry, looks like we can’t complete that transaction. Please note: Hubble does not accept prepaid cards.")
        ReactGA.event({
          category: 'User',
          action: 'Attempted to use a prepaid card'
        });

        return false;
      }

      // setSubmitCopy("Complete Order")
      if (responseToken.paymentMethod){
        setUser({...values, payment_method: await responseToken.paymentMethod?.id })
      }

      if (responseToken?.error) {
        setCardErrors(responseToken.error?.message)
        setSubmitButtonText('COMPLETE ORDER')
        setSubmitCopy("Complete Order")
      }
    }
    else {
      submitUser(values)
    }
  };

  const validateField = (e) => {
    if (e.target.name === 'email') {
      const isEmailValid = isValidEmail(values.email)
      setIsEmailValidationError(!isEmailValid)
      !e.target.value && setIsEmailValidationError(false)
    }
    e.target.value === ""
    ? setErrors({...errors, [e.target.name]: true})
    : setErrors({...errors, [e.target.name]: false})
  }

  const submitDisabled = () => {
    if (values.email === "" || values.first_name === "" || values.last_name === "" || values.address1 === "" ||values.zipcode === "" ||values.province === ""  ){
      return true
    }
    return submit_copy === "Complete Order" ? false : true
  }

  useEffect(()=> {
    brand?.value === 'hydro' || brand?.value === 'skyhy'? setShipping('$3.99') : setShipping('$0.00')
  }, [hubbleOrderContext])

  const { price, value } = brand

  return (
    <StepWrapper
      title='Order summary'
      steps={steps}
      currentStep='Checkout'
      className='checkout'
      onSubmit={async (e) => {
        const canSubmit = submit_copy === "Complete Order" && !isEmailValidationError
        if (canSubmit) {
          handleSubmit(e)
          tiktokTrack('AddBilling')
        }
      }}
    >
      <div className="disclaimer">
        <p>NOTE: Your order will be processed after we review your prescription or communicate with your doctor. This typically takes about 48 hours, but may take a little longer and delay shipment.</p>
        <p>We will email you status updates along the way.</p>
      </div>
      <div className="order-info">
        <div className="image-block">
          <WebpImage fileName="Pages/Intake/contact-lens-checkout.png" alt="" />
        </div>
        <div className={isHydroSkyhy ? "text-block-hydro-skyhy" : "text-block"}>
          <div className="product">
            {isHydroSkyhy && <p className="line-item">
              <span className="name">{brand?.short_title || brand?.title}</span>
              <p className={brand.value === "hubble" ? 'price classic' : 'price'}>{brand.value !== "hubble" && <span className="crossed-price">${+price}/eye</span>} ${Number(brand?.sale_price) || Number(price)}{brand.value !== "hubble" && '/eye'}
                <p className="price-description">
                  {(brand.value === "hydro" || brand.value === "skyhy") ?
                    isPromo || brand.value === "hydro" ? "60% OFF your first order" : "40% OFF your first order" :
                    brand.value !== "hubble" && `${brand.percent_off}% OFF your first order`}
                </p>
              </p>
            </p>}

            {!isHydroSkyhy && <p className="line-item">
              <span className="name">{brand.title}<span className="quantity"> x {quantity}</span></span>
              <span className="price">${subTotal}</span>
            </p>}

            <p className="line-item-disclaimer">We will email you status updates along the way</p>
          </div>
          <div className="subtotal">
            <p className="line-item">
              <span className="name">Subtotal</span>
              <span className="price">${subTotal}</span>
            </p>
            {value === "hubble" && <p className="line-item-disclaimer">28-day subscription begins after $1 offer is redeemed.</p>}
            <p className="line-item">
              <span className="name">Shipping</span>
              {isHydroSkyhy && <span className="price">{shipping}</span>}
              {!isHydroSkyhy && <span className="price">$0.00</span>}
            </p>
            <p className="line-item">
              <span className="name">Taxes</span>
              <span className="price">${brand.value === "hubble" ? "0.00" : taxes || "0.00"}</span>
            </p>
            <p className="line-item">
              <span className="name">Total</span>
              {isHydroSkyhy && <span className="price">${totalWithShipmentAndTaxes}</span>}
              {!isHydroSkyhy && <span className="price">${total}</span>}
            </p>
          </div>
          <div className="total">
            <p className="line-item">
              <span className="name">Payment Due</span>
              <div className="price-container">
               {!isHydroSkyhy && <>
                <span className="currency">{currency}</span>
                <span className="price">${total}</span>
                </>}
               {isHydroSkyhy && <span className="price">${totalWithShipmentAndTaxes}</span>}
              </div>
            </p>
          </div>
        </div>
      </div>
      <div className="customer-info">
        <div className='delivery-block'>
          <h4 className="title">Delivery Information</h4>
          <div className="inputs-container">
            <div className="form-group-columns name">
              <div className="form-group">
                <input className="form-input" type="text" onBlur={(e)=> validateField(e) } required placeholder="First Name" name="first_name" value={values.first_name} onChange={(e) => handleInputChange(e)}/>
                {errors.first_name &&
                  <p className="error">First Name is required</p>
                }
              </div>
              <div className="form-group">
                <input className="form-input" type="text"  onBlur={(e)=> validateField(e) }  required={true} placeholder="Last Name" name="last_name" value={values.last_name}  onChange={(e) => handleInputChange(e)}/>
                {errors.last_name &&
                  <p className="error">Last Name is required</p>
                }
              </div>
            </div>
            <div className="form-group-columns address">
              <div className="form-group">
                <input className="form-input" type="text" onBlur={(e)=> {validateField(e); values.address1 === "" && setAddresses([]) }}  required={true} placeholder="Address Line 1" name="address1" value={values.address1}
                  onChange={(e) => {
                  handleInputChange(e);
                  loqate()
                  }
                }/>
                {errors.address1 &&
                  <p className="error">Address is required</p>
                }
                { addresses.length >= 1 && values.address1.length >=1 &&
                  <div className="address-dropdown">
                    {addresses.map((address) => {
                      return <p className="address" onClick={() => autoFillAddress(address)} ><strong>{address.Text}</strong> {address.Description}</p>
                    })}
                  </div>
                }
              </div>
              <div className="form-group">
                <input className="form-input" type="text" placeholder="Apt Suite" name="address2" value={values.address2}  onChange={(e) => handleInputChange(e)}/>
              </div>
            </div>
            <div className="form-group-columns city-state-zip">
              <div className="form-group city">
                <input className="form-input" type="text" onBlur={(e)=> validateField(e) } required={true} placeholder="City" name="city" value={values.city}  onChange={(e) => handleInputChange(e)}/>
                {errors.city &&
                  <p className="error">City is required</p>
                }
              </div>
              <div className="form-group state">
                <Select
                  name="province"
                  className="hubble-select"
                  classNamePrefix="HubbleSelect"
                  value={states.find(e => e.value === values.province)}
                  onChange={e => handleInputChange({target: {name: "province", value: e.value}})}
                  placeholder="State"
                  options={states}
                  required/>
                {errors.province &&
                  <p className="error">State is required</p>
                }
              </div>
              <div className="form-group zipcode">
                <input className="form-input " type="text" onBlur={(e)=> validateField(e) } required={true} placeholder="Zipcode" name="zipcode" value={values.zipcode}  onChange={(e) => handleInputChange(e)}/>
                {errors.zipcode
                ? <p className="error">Zipcode is required</p>
                : shippingAddress && (shippingAddress.zipcode && !zipRegEx.test(shippingAddress.zipcode))
                ? <p className="error">Invalid zip code</p>
                : ''
                }
              </div>
            </div>
            <div className="form-group-columns phone">
              <div className="form-group">
                <input className="form-input" type="text" onBlur={(e)=> validateField(e) }  placeholder={"Phone" } name="phone" required={true} value={values.phone}  onChange={(e) => handleInputChange(e)}/>
                {errors.phone &&
                  <p className="error">Your phone number is required in order for us to verify your prescription.</p>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="payment-block">
          <h4 className="title">Payment Information</h4>
          <div className="inputs-container">
            <div className="form-group">
              <input className="form-input"  type="email" name="email" onBlur={(e) => { klaviyoTrack(e); validateField(e); setIsFirstTimeEmailEdit(false) }} required={true} value={values.email} onChange={(e) => handleInputChange(e)} placeholder="Enter your email" />
              {errors.email
              ? <p className="error">Email is required</p>
              : isEmailValidationError
              ? <p className="error">Email is invalid</p>
              : ''
              }
            </div>
            <div className="form-group card-payment">
              {paymentRequest && (
                <div>
                  <PaymentRequestButtonElement
                    className="mobile-pay"
                    options={{
                      paymentRequest
                    }}
                    onClick={updatePaymentRequestInstance}
                  />
                </div>
              )}
              <div className={`payment-select-wrapper ${errors.paymentType && !values.paymentType ? 'error' : ''}`.trim()}>
                  Credit Card
              </div>
              <div className="card-holder">
                <CardElement />
                <WebpImage fileName="Pages/Intake/powered_by_stripe.svg" alt="stripe-logo"/>
              </div>
            </div>
            <div className="form-group paypal">
              <div className={`payment-select-wrapper ${errors.paymentType && !values.paymentType ? 'error' : ''}`.trim()}>
                Pay with <WebpImage className="small-paypal" fileName="Pages/Intake/paypal.svg" alt="paypal" />
              </div>

              <p className="paypal-copy">Click Here:   <PayPalButton
                braintree={ braintree }
                commit={true }
                env={ process.env.REACT_APP_BRAINTREE_ENV }
                client={ {sandbox: 'sandbox_tvynb2kv_jt9yb92tffv8pprd', production: 'production_9qpr3jqc_qvfxwnzxr53brmt8' }}
                payment={ (data, actions) => payment(data, actions) }
                onAuthorize={ (data, actions) => onAuthorize(data, actions) }
              /></p>
            </div>
          </div>
          <div className="disclaimer-block">
            <p className="card-errors">{cardErrors}</p>
            <p className="subscription" dangerouslySetInnerHTML={{ __html: subscriptionDisclaimer }} />
            {isIronclad &&
              <div className={`ironclad-wrapper subscription ${ironcladWarning ? "warning" : ""}`} >
                <PrescriptionRadio
                  value="ironclad"
                  label={`I agree to sign up for a Hubble subscription and to <a href="https://tos.hubblecontacts.com/" target="_blank">Hubble’s Terms of Service</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a>`}
                  onClick={() => handleClickWrap()}
                  active={ironclad}
                />
              </div>
            }
          </div>
        </div>
      </div>
    </StepWrapper>
  );
};

export default Checkout;

import WebpImage from '../../WebpImage';
import HeaderLogo from "../../../images/assets/Header/Logo.svg"
import React from 'react';
import './styles.scss';

const Nav = ({isAffiliatePage, isV3}) => {
  let search = typeof window !== "undefined" ? window?.location?.search : null
  return (
    <div className={`fb-landing-nav ${isAffiliatePage ? "affiliate-page" : ""}`}>
      {!isAffiliatePage && (
        <div className='logo-wrapper-desktop'>
          {
            isV3 ? 
            <WebpImage fileName={'Pages/Intake/hubble-logo-desktop.svg'} className="hubble-logo" /> : 
            <a href={`https://www.hubblecontacts.com/${search}`}>
              <WebpImage fileName={'Pages/Intake/hubble-logo-desktop.svg'} className="hubble-logo" />
            </a>
          }
        </div>
      )}
      <div className='logo-wrapper-mobile'>
        {
          isV3 ?  <HeaderLogo className="hubble-logo" /> : 
          <a href={`https://www.hubblecontacts.com/${search}`}>
            <HeaderLogo className="hubble-logo" />
          </a>
        }
      </div>
    </div>
  )
}

export default Nav;
